<template>
    <div class="reset-password">
        <nav-bar />
        <div class="reset-password-container container">
            <transition name="slide-down">
                <div v-if="isRequestSent" class="row">
                    <div class="col">
                        <h1>Password Updated!</h1>
                        <p class="mb-4">
                            Now you can type in the new password to log into<br>your account.
                        </p>
                        <button class="btn btn-primary" @click="$modal.show('auth-modal', { allowClose: true, showSignIn: true, redirectRoute: { name: 'web-app' } })">
                            Sign in
                        </button>
                    </div>
                </div>
            </transition>
            <div v-if="!isRequestSent" class="row">
                <div class="col">
                    <h1>Reset password?</h1>
                    <p>Type your new password to log into your account.</p>
                    <ValidationObserver v-slot="{ valid, handleSubmit }" slim>
                        <form novalidate @submit.prevent="handleSubmit(resetPassword)">
                            <ValidationProvider
                                v-slot="{ errors }"
                                mode="lazy"
                                name="password"
                                rules="required|min:8"
                            >
                                <div :class="{ 'required' : errors.length }" class="form-group">
                                    <label for="password">Password</label>
                                    <span v-if="errors.length">({{ errors[0] }})</span>
                                    <input
                                        id="password"
                                        v-model="password"
                                        type="password"
                                        class="form-control"
                                        placeholder="Enter your password"
                                    >
                                </div>
                            </ValidationProvider>
                            <ValidationProvider
                                v-slot="{ errors }"
                                name="confirm password"
                                rules="required|passwordConfirm:@password"
                            >
                                <div :class="{ 'required' : errors.length }" class="form-group">
                                    <label for="confirmPassword">Confirm password</label>
                                    <span v-if="errors.length">({{ errors[0] }})</span>
                                    <input
                                        id="confirmPassword"
                                        v-model="confirmPassword"
                                        type="password"
                                        class="form-control"
                                        placeholder="Enter your confirm password"
                                    >
                                </div>
                            </ValidationProvider>
                            <button :disabled="!valid" class="btn btn-primary">
                                Confirm
                            </button>
                        </form>
                    </validationObserver>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { extend } from "vee-validate";

extend("passwordConfirm", {
    params: ["target"],
    validate(value, { target }) {
        return value === target;
    },
    message: "Password confirmation does not match"
});

export default {
    name: "ResetPassword",
    components: {
        NavBar: () => import(/* webpackChunkName: "nav-bar" */ "@c/organisms/nav-bar")
    },
    data() {
        return {
            isRequestSent: false,
            password: "",
            confirmPassword: ""
        }
    },
    methods: {
        resetPassword() {
            const token = this.$route.params.token;

            const data = {
                new_password: this.password,
                verify_password: this.confirmPassword
            };

            axiosPublic.post(`/auth/reset/${token}`, data).then(({ data }) => {
                this.isRequestSent = true;
                console.log(data)
            }).catch((error) => {
                console.log(error.response.data.errors.message)

                this.$notify({
                    group: "app-notifications",
                    type: "error",
                    text: error.response.data.errors.message,
                    duration: 3000
                });
            })
        }
    }
}
</script>
