var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"reset-password"},[_c('nav-bar'),_c('div',{staticClass:"reset-password-container container"},[_c('transition',{attrs:{"name":"slide-down"}},[(_vm.isRequestSent)?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('h1',[_vm._v("Password Updated!")]),_c('p',{staticClass:"mb-4"},[_vm._v(" Now you can type in the new password to log into"),_c('br'),_vm._v("your account. ")]),_c('button',{staticClass:"btn btn-primary",on:{"click":function($event){return _vm.$modal.show('auth-modal', { allowClose: true, showSignIn: true, redirectRoute: { name: 'web-app' } })}}},[_vm._v(" Sign in ")])])]):_vm._e()]),(!_vm.isRequestSent)?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('h1',[_vm._v("Reset password?")]),_c('p',[_vm._v("Type your new password to log into your account.")]),_c('ValidationObserver',{attrs:{"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var handleSubmit = ref.handleSubmit;
return [_c('form',{attrs:{"novalidate":""},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.resetPassword)}}},[_c('ValidationProvider',{attrs:{"mode":"lazy","name":"password","rules":"required|min:8"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-group",class:{ 'required' : errors.length }},[_c('label',{attrs:{"for":"password"}},[_vm._v("Password")]),(errors.length)?_c('span',[_vm._v("("+_vm._s(errors[0])+")")]):_vm._e(),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.password),expression:"password"}],staticClass:"form-control",attrs:{"id":"password","type":"password","placeholder":"Enter your password"},domProps:{"value":(_vm.password)},on:{"input":function($event){if($event.target.composing){ return; }_vm.password=$event.target.value}}})])]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"confirm password","rules":"required|passwordConfirm:@password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-group",class:{ 'required' : errors.length }},[_c('label',{attrs:{"for":"confirmPassword"}},[_vm._v("Confirm password")]),(errors.length)?_c('span',[_vm._v("("+_vm._s(errors[0])+")")]):_vm._e(),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.confirmPassword),expression:"confirmPassword"}],staticClass:"form-control",attrs:{"id":"confirmPassword","type":"password","placeholder":"Enter your confirm password"},domProps:{"value":(_vm.confirmPassword)},on:{"input":function($event){if($event.target.composing){ return; }_vm.confirmPassword=$event.target.value}}})])]}}],null,true)}),_c('button',{staticClass:"btn btn-primary",attrs:{"disabled":!valid}},[_vm._v(" Confirm ")])],1)]}}],null,false,2995156811)})],1)]):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }